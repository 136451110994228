import React from "react"
import Card from "../../Card/card"
import styles from "./benefits.module.css"
import FadeInSection from "../../FadeInSection/fadeInSection"
import nutritionIcon from "../../../../static/nutrition-icon.svg"
import programmingIcon from "../../../../static/programming-icon.svg"
import dataIcon from "../../../../static/data-icon.svg"
import Button from "../../Button/button"
import { useFormContext } from "../../../context/formContext"

function BenefitsSection() {
  const { setHidden } = useFormContext()

  function handleFormOpen() {
    setHidden(false)
  }

  return (
    <section>
      <div className={styles.container}>
        <FadeInSection>
          <div className={styles.heading}>
            <h2>
              <span className="underline">FOCUS</span>
              <span> ON WHAT MATTERS</span>
            </h2>
            <p className={styles.text}>
              Track your health and fitness like never before. Combining state
              of the art equipment and elite-level professionals, we'll help you
              transform your approach to health and wellbeing. By providing a
              truly unique, 360 support package, our bespoke programmes are
              designed to help you achieve your goals!
            </p>
          </div>
        </FadeInSection>

        <div className="row justify-between">
          <div className="col-30">
            <FadeInSection>
              <Card>
                <h4 className="mb-1">
                  World Class <span className="text-blue">Nutrition</span>{" "}
                </h4>
                {/* <SupportIcon /> */}
                <div>
                  <img
                    width="100px"
                    height="auto"
                    src={nutritionIcon}
                    alt="Mondays Logo"
                    className="mb-0 mt-0"
                  />
                </div>
              </Card>
            </FadeInSection>
          </div>
          <div className="col-30">
            <FadeInSection>
              <Card>
                <h4 className="mb-1">
                  <span className="text-purple">Expert</span> Programming
                </h4>
                {/* <ProgrammingIcon /> */}
                <div>
                  <img
                    width="100px"
                    height="auto"
                    src={programmingIcon}
                    alt="Mondays Logo"
                    className="mb-0 mt-0"
                  />
                </div>
              </Card>
            </FadeInSection>
          </div>
          <div className="col-30">
            <FadeInSection>
              <Card>
                <h4 className="mb-1">
                  Science-led <span className="text-blue">Data</span>
                </h4>
                {/* <TrackingIcon /> */}
                <div>
                  <img
                    width="100px"
                    height="auto"
                    src={dataIcon}
                    alt="Mondays Logo"
                    className="mb-0 mt-0"
                  />
                </div>
              </Card>
            </FadeInSection>
          </div>
        </div>
        <div className={styles.action}>
          <Button onClick={handleFormOpen} shadow large secondary>
            Start Today
          </Button>
        </div>
      </div>
    </section>
  )
}

export default BenefitsSection
