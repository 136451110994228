import React from "react"
import FadeInSection from "../../FadeInSection/fadeInSection"
import image from "../../../../static/mondays-results.svg"

function ResultsSection() {
  return (
    <FadeInSection>
      <section>
        <div className="row">
          <div className="col-40">
            <h2 className="underline">
              POSITIVE PROGRESSIVE SUSTAINABLE RESULTS
            </h2>
            <p>
              We're not here to transform you overnight. We're here for your
              continual success. We aim to help you change your mindset,
              providing long term education, support and accountability,
              tailored towards positive, progressive and sustainable results.
              Whatever your goals, age or experience level, we’re here to help
              you succeed.
            </p>
          </div>
          <div className="col-60">
            <div>
              <img
                src={image}
                width="100%"
                height="auto"
                alt="Positive, Progressive, Sustainable Results"
              />
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  )
}

export default ResultsSection
