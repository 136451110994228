import React from "react"
import FadeInSection from "../../FadeInSection/fadeInSection"
import image from "../../../../static/mondays-main.svg"

function WhatWeDoSection() {
  return (
    <FadeInSection>
      <section>
        <div className="row">
          <div className="col-50">
            <h2>
              <span>GET THAT </span>
              <span className="underline">MONDAY</span>
              <span> FEELING</span>
            </h2>
            <p>
              Have you ever wanted expert advice from industry-leading health
              professionals? A bespoke programme tailored to your needs? Someone
              to support you with nutrition, and in the gym on a weekly basis?
              <br />
              <br />
              We want to turn the dogma of the Monday blues into something to
              get excited about - providing the ultimate 360-degree health
              service and expert guidance from qualified professionals.
            </p>
          </div>
          <div className="col-50">
            <div>
              <img src={image} width="100%" height="100%" alt="About Mondays" />
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  )
}

export default WhatWeDoSection
