import React from "react"
import Card from "../../Card/card"
import Button from "../../Button/button"
import FadeInSection from "../../FadeInSection/fadeInSection"
import { useFormContext } from "../../../context/formContext"
import styles from "./journey.module.css"
import cn from "classnames"
import logo from "../../../../static/mondays-infinite.gif"

function JourneySection() {
  const { setHidden } = useFormContext()

  function handleFormOpen() {
    setHidden(false)
  }

  return (
    <FadeInSection>
      <section>
        <div className="row center">
          <div className="col-100">
            <h2>
              <span>START YOUR JOURNEY </span>
              <span className="underline">TODAY</span>
            </h2>
          </div>
          <div className="col-100">
            <div className={styles.container}>
              <div className="row">
                <div className={cn(styles.left, "col-50")}>
                  <h3>
                    <span>FIND THE RIGHT PACKAGE FOR </span>
                    <span className="text-blue">YOU</span>
                  </h3>
                  <p className="px-2">
                    Answer a few short questions and hear from a member of our
                    team - it's that easy!
                  </p>
                  <Button onClick={handleFormOpen} large secondary>
                    Start Today
                  </Button>
                </div>
                <div className={cn(styles.right, "col-50")}>
                  <img
                    id="infinite-m"
                    src={logo}
                    alt="Mondays Infinite M GIF"
                    width="500px"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  )
}

export default JourneySection
