import React from "react"
import styles from "./modal.module.css"
import cn from "classnames"
import Button from "../Button/button"
import MondaysSmallIcon from "../icons/mondaysSmall"

export default function ModalOverlay({
  children,
  onSave,
  onClose,
  hidden,
  header,
}) {
  return (
    <div className={cn(styles.overlay, hidden && styles.hidden)}>
      <div className={cn(styles.modal, "shadow")} hidden={hidden}>
        <div className={styles.modalHeader}>
          <h1 className={cn(styles.headerText, "underline")}>{header}</h1>
        </div>

        <div className={styles.content}>{children}</div>
        <div className={styles.actions}>
          {/* <div className={cn(styles.cancelButtonDiv)}>
              <Button onClick={onClose}>Cancel</Button>
            </div>
            <div className={cn(styles.confirmButtonDiv)}>
              <Button onClick={onSave}>Save</Button>
            </div> */}
        </div>
      </div>
    </div>
  )
}
