import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import image from "../../static/mondays-main.svg"
import WhatWeDoSection from "../components/Content/WhatWeDo/whatWeDo"
import BenefitsSection from "../components/Content/Benefits/benefits"
import WhoWeAreSection from "../components/Content/WhoWeAre/whoWeAre"
import ResultsSection from "../components/Content/Results/results"
import JourneySection from "../components/Content/Journey/journey"
import Form from "../components/Form/form"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Mondays"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          metaImage={image}
          pathname={this.props.location.pathname}
          keywords={[
            `fitness`,
            `gym`,
            `mondays`,
            `health`,
            `wellbeing`,
            `nutrition`,
            `wellness`,
            `workout`,
            `personal training`,
            `body composition`,
          ]}
        />
        <div>
          <WhatWeDoSection />
          <BenefitsSection />
          <WhoWeAreSection />
          <ResultsSection />
          <JourneySection />
          <Form />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
