import React from "react"
import FadeInSection from "../../FadeInSection/fadeInSection"
import image from "../../../../static/mondays-team.svg"

function WhoWeAreSection() {
  return (
    <FadeInSection>
      <section>
        <div className="row-reverse">
          <div className="col-50">
            <h2>
              <span>MEET </span>
              <span className="underline">THE TEAM</span>
            </h2>
            <p>
              We’re proud to be a team brimming with professional advice and
              expertise, passionate about helping you succeed.
              <br />
              <br />
              With the full support team from a National Award Winning Health
              Club, alongside UKSCA acredited coaches, a doctoral researcher in
              nutrition and elite physiotherapists, we strive to put you in
              front of the best in the business.
            </p>
          </div>
          <div className="col-50">
            <div>
              <img
                src={image}
                width="100%"
                height="auto"
                alt="Mondays Meet the Team"
              />
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  )
}

export default WhoWeAreSection
