import React, { useState, useEffect } from "react"
import Modal from "../Modal/modal"
import { useFormContext } from "../../context/formContext"
import styles from "./form.module.css"
import cn from "classnames"
import HorizontalLinearStepper from "../../components/Stepper/stepper"

const ContactForm = () => {
  const [formPage, setFormPage] = useState(1)
  const { hidden, setHidden } = useFormContext()

  function incrementForm() {
    if (formPage < 5) setFormPage(formPage + 1)
  }
  function decrementForm() {
    if (formPage > 1) setFormPage(formPage - 1)
  }
  function closeForm() {
    setHidden(true)
  }
  function setFormPref(data = "none") {
    localStorage && localStorage.setItem("formPref", data)
  }

  return (
    <div>
      <Modal hidden={hidden}>
        <HorizontalLinearStepper activeStep={formPage - 1} />
        {/* <h5>{formPage} of 5</h5> */}
        <form
          name="Mondays Contact"
          method="POST"
          data-netlify="true"
          action="/thank-you"
          className={styles.form}
        >
          <input type="hidden" name="form-name" value="Mondays Contact" />

          {/* ----------------Page 1---------------- */}
          <fieldset hidden={formPage !== 1}>
            <legend className={styles.question}>
              What level of gym experience do you have?
            </legend>
            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="level" value="beginner" />
                Beginner
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="level" value="intermediate" />
                Intermediate
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="level" value="advanced" />
                Advanced
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="level" value="athlete" />
                Athlete
              </label>
            </p>
          </fieldset>

          {/* ----------------Page 2---------------- */}
          <fieldset hidden={formPage !== 2}>
            <legend className={styles.question}>
              What is your primary goal?
            </legend>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="primary goal" value="weight" />
                Weight
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="primary goal" value="nutrition" />
                Nutrition
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="primary goal" value="performance" />
                Performance
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="primary goal" value="lifestyle" />
                Lifestyle
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="primary goal" value="other" />
                Other (please specifiy)
              </label>
            </p>

            <p className={styles.answerContainer}>
              <legend> </legend>
              <textarea
                name="goal additional info"
                placeholder="Anything else you'd like us to know?"
              />
            </p>
          </fieldset>

          {/* ----------------Page 3---------------- */}
          <fieldset hidden={formPage !== 3}>
            <legend className={styles.question}>
              What equipment do you have access to currently?
            </legend>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="equipment"
                  value="full range of gym equipment"
                />
                Full range of gym equipment
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="equipment"
                  value="range of home equipment"
                />
                Range of home equipment
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="equipment" value="no equipment" />
                No equipment
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input type="radio" name="equipment" value="other" />
                Other (please specify)
              </label>
            </p>

            <p className={styles.answerContainer}>
              <legend> </legend>
              <textarea
                name="equipment additional info"
                placeholder="Anything else you'd like us to know?"
              />
            </p>
          </fieldset>

          {/* ----------------Page 4----------------*/}
          <fieldset hidden={formPage !== 4}>
            <legend className={styles.question}>
              How often do you feel you'd benefit from 1on1 training?
            </legend>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="training"
                  value="once a week"
                  onChange={() => setFormPref("starter")}
                />
                Once a week
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="training"
                  value="twice a week"
                  onChange={() => setFormPref("club")}
                />
                Twice a week
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="training"
                  value="three or more times a week"
                  onChange={() => setFormPref("team")}
                />
                Three or more times a week
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="training"
                  value="online support"
                  onChange={() => setFormPref("online")}
                />
                Online Support
              </label>
            </p>

            <p className={cn(styles.answerContainer, "flex")}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="training"
                  value="unsure at this time"
                  onChange={() => setFormPref("none")}
                />
                Unsure at this time
              </label>
            </p>
          </fieldset>

          {/* ----------------Page 5---------------- */}
          <fieldset hidden={formPage !== 5}>
            <p className={styles.answerContainer}>
              <legend>Full Name *</legend>
              <input
                className="input--text"
                name="name"
                placeholder="Joe Bloggs"
                pattern=".*\S+.*"
                required
              />
            </p>
            <p className={styles.answerContainer}>
              <legend>Email Address *</legend>
              <input
                className="input--text"
                placeholder="example@mondays.com"
                type="email"
                name="email"
                required
              />
            </p>
            <p className={styles.answerContainer}>
              <legend>Contact Number *</legend>
              <input
                className="input--text"
                name="contact number"
                placeholder="07878 787 878"
                type="tel"
                required
              ></input>
            </p>

            <p className={styles.answerContainer}>
              <legend>Age Range</legend>
              <select name="age range">
                <option value="18-26">18-26</option>
                <option value="27-35">27-35</option>
                <option value="36-44">36-44</option>
                <option value="45-53">45-53</option>
                <option value="54+">54+</option>
              </select>
            </p>
          </fieldset>

          {/* ----------------Page 6---------------- */}

          {/* <div hidden={formPage !== 6}>
            {trainingState && trainingState !== 4 && (
              <div>
                <p className={styles.answerContainer}>
                  Based on your answers we'd reccommend the following package:
                </p>
                {trainingState && (
                  <div>
                    <input
                      type="radio"
                      name="reccommended"
                      value="mondays club"
                      id="club"
                      checked={trainingState === 1}
                      onChange={() => setTrainingState(1)}
                    />
                    <label for="club">Mondays Club</label>

                    <input
                      type="radio"
                      name="reccommended"
                      value="mondays team"
                      id="team"
                      checked={trainingState === 2}
                      onChange={() => setTrainingState(2)}
                    />
                    <label for="team">Mondays Team</label>

                    <input
                      type="radio"
                      name="reccommended"
                      value="mondays vision"
                      id="vision"
                      checked={trainingState === 3}
                      onChange={() => setTrainingState(3)}
                    />
                    <label for="vision">Mondays Vision</label>
                  </div>
                )}
              </div>
            )}

            {trainingState && trainingState === 4 && (
              <div>
                <p className={styles.answerContainer}>See our range of bespoke packages:</p>
                <input
                  type="radio"
                  name="reccommended"
                  value="mondays club"
                  id="club"
                />
                <label for="club">Mondays Club</label>

                <input
                  type="radio"
                  name="reccommended"
                  value="mondays team"
                  id="team"
                />
                <label for="team">Mondays Team</label>

                <input
                  type="radio"
                  name="reccommended"
                  value="mondays vision"
                  id="vision"
                />
                <label for="vision">Mondays Vision</label>
              </div>
            )}
          </div> */}

          {/* ----------------Submit---------------- */}
          <div className={styles.navigation}>
            <p className={styles.cancel} onClick={closeForm}>
              Cancel
            </p>
            <div>
              <button
                type="button"
                className={cn(styles.navButton, styles.navButtonGrey)}
                disabled={formPage === 1}
                onClick={decrementForm}
              >
                Previous
              </button>
              <button
                type="button"
                className={cn(styles.navButton, styles.navButtonBlue)}
                hidden={formPage === 5}
                onClick={incrementForm}
              >
                Next
              </button>
              <button
                className={cn(styles.navButton, styles.submit)}
                hidden={formPage !== 5}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default ContactForm
